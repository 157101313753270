<template>
  <el-card class="incomplete-verification-form">
    <div slot="header">
      <span>Verificación Incompleta</span>
    </div>
    <el-alert title="Contestar únicamente si la verificación no se pudo iniciar o concluir" type="error" :closable="false">
    </el-alert>
    <div class="input-container">
      <label>¿Qué ocurrió en la entrevista?</label>
      <el-radio-group v-model="status">
        <el-radio-button :label="0">No se inició</el-radio-button>
        <el-radio-button :label="1" >No se completó</el-radio-button>
      </el-radio-group>
    </div>
    <el-input placeholder="¿Por qué?" type="textarea" v-model="reason">
    </el-input>
  </el-card>
</template>

<script>
export default {
  name: 'IncompleteVerificationForm',
  data () {
    return {
      status: 0,
      reason: ''
    }
  }
}
</script>

<style scoped lang="scss">
.input-container {
  display: grid;
  margin: 1rem 0;
}
</style>
