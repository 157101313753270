<template>
  <el-card class="photos-form">
    <div slot="header">
      <span>Fotos</span>
    </div>

    <div class="forms-section">
      <el-card>
        <div slot="header">
          <span>Foto 1</span>
        </div>
        <div class="btn-section">
          <el-button type="primary" icon="el-icon-camera">
            Tomar Foto
          </el-button>
          <el-button type="primary" icon="el-icon-location">
            Ver Mapa Offline
          </el-button>
          <el-button type="primary" icon="el-icon-location">
            Ver Mapa Online
          </el-button>
        </div>
        <div>
          <el-input disabled>
            <template slot="prepend">Latitud</template>
          </el-input>
          <el-input disabled>
            <template slot="prepend">Latitud</template>
          </el-input>
          <el-input disabled>
            <template slot="prepend">Longitud</template>
          </el-input>
          <el-input disabled>
            <template slot="prepend">Longitud</template>
          </el-input>
          <el-input disabled>
            <template slot="prepend">Proximidad</template>
            <template slot="append">m</template>
          </el-input>
          <el-input disabled>
            <template slot="prepend">Proximidad</template>
            <template slot="append">m</template>
          </el-input>
          <el-input disabled>
            <template slot="prepend">Fecha</template>
          </el-input>
          <el-input disabled>
            <template slot="prepend">Fecha</template>
          </el-input>
          <el-input disabled>
            <template slot="prepend">Hora</template>
          </el-input>
          <el-input disabled>
            <template slot="prepend">Hora</template>
          </el-input>
        </div>
      </el-card>

      <el-card>
        <div slot="header">
          <span>Foto 2</span>
        </div>
        <el-button type="primary" icon="el-icon-camera">
          Tomar Foto
        </el-button>
      </el-card>
      <el-card>
        <div slot="header">
          <span>Foto 3</span>
        </div>
        <el-button type="primary" icon="el-icon-camera">
          Tomar Foto
        </el-button>
      </el-card>
      <el-card>
        <div slot="header">
          <span>Foto 4</span>
        </div>
        <el-button type="primary" icon="el-icon-camera">
          Tomar Foto
        </el-button>
      </el-card>
      <el-card>
        <div slot="header">
          <span>Foto 5</span>
        </div>
        <el-button type="primary" icon="el-icon-camera">
          Tomar Foto
        </el-button>
      </el-card>
    </div>
  </el-card>
</template>

<script>
export default {
  name: 'PhotosForm'
}
</script>

<style scoped lang="scss">
.photos-form {
  margin-top: 1rem;
}

.btn-section {
  display: flex;
  flex-direction: column;

  .el-button {
    margin: 0.25rem 0;
  }
}
</style>
