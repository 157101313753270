<template>
  <el-card class="section-a-form">
    <div slot="header">
      <span>Sección A</span>
    </div>
    <div class="input-container">
      <label>Fecha</label>
      <el-date-picker
          type="date"
          placeholder="Fecha"
          v-model="date"
          />
    </div>
    <div class="input-container">
      <label>rec_id</label>
      <el-input
          placeholder="rec_id"
          v-model="recId"
          />
    </div>
    <div class="input-container">
      <label>Folio</label>
      <el-input
          placeholder="Folio"
          v-model="folio"
          />
    </div>
    <div class="input-container">
      <label>Verificación</label>
      <el-input
          placeholder="Verificación"
          v-model="verification"
          />
    </div>
    <div class="input-container">
      <label>Longitud</label>
      <el-input
          placeholder="Longitud"
          v-model="long"
          />
    </div>
    <div class="input-container">
      <label>Latitud</label>
      <el-input
          placeholder="Latitud"
          v-model="lat"
          />
    </div>
    <div class="input-container">
      <label>Proximidad</label>
      <el-input
          placeholder="Proximidad"
          v-model="proximity"
          />
    </div>
    <div>
      <el-button type="primary" icon="el-icon-camera">
        Obtener Georreferencias
      </el-button>
    </div>
  </el-card>
</template>

<script>
export default {
  name: 'SectionAForm',
  data () {
    return {
      date: null,
      recId: '',
      folio: '',
      verification: '',
      long: '',
      lat: '',
      proximity: ''
    }
  }
}
</script>

<style scoped lang="scss">
.section-a-form {
  margin: 1rem 0;
}

.input-container {
  flex-direction: column;
  display: flex;
  margin: 1rem 0;

  > * {
    width: 100%;
  }
}
</style>
