<template>
  <div class="beneficiary">
    <el-tabs v-model="activeTab" type="card">
      <el-tab-pane label="Verificación" name="0">
        <SectionAForm />
        <InterventionDataForm />
        <PhotosForm />
      </el-tab-pane>
      <el-tab-pane label="Verificación Incompleta" name="1">
        <IncompleteVerificationForm />
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import SectionAForm from '@/components/SectionAForm'
import InterventionDataForm from '@/components/InterventionDataForm'
import PhotosForm from '@/components/PhotosForm'
import IncompleteVerificationForm from '@/components/IncompleteVerificationForm'

export default {
  name: 'Beneficiary',
  components: {
    SectionAForm,
    InterventionDataForm,
    PhotosForm,
    IncompleteVerificationForm
  },
  data () {
    return {
      activeTab: '0'
    }
  }
}
</script>

<style scoped lang="scss">
.beneficiary {
  margin: 1rem 0.5rem;
  display: grid;
  justify-items: center;

  .el-tabs {
    width: 100%;
  }
}
</style>
