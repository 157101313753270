<template>
  <el-card class="intervention-data-form">
    <div slot="header">
      <span>Datos de la intervención</span>
    </div>

    <div class="forms-section">
      <el-card>
        <div slot="header">
          <span>Preeliminares</span>
        </div>
        <el-input disabled>
          <template slot="prepend">$</template>
        </el-input>
        <el-input placeholder="Observaciones" type="textarea" v-model="preliminaries.observations">
        </el-input>
        <el-input placeholder="Porcentaje" v-model="preliminaries.percentage">
          <template slot="append">%</template>
        </el-input>
      </el-card>

      <el-card>
        <div slot="header">
          <span>Demoliciones</span>
        </div>
        <el-input disabled>
          <template slot="prepend">$</template>
        </el-input>
        <el-input placeholder="Observaciones" type="textarea" v-model="demolitions.observations">
        </el-input>
        <el-input placeholder="Porcentaje" v-model="demolitions.percentage">
          <template slot="append">%</template>
        </el-input>
      </el-card>

      <el-card>
        <div slot="header">
          <span>Estructura / Albañilerías</span>
        </div>
        <el-input disabled>
          <template slot="prepend">$</template>
        </el-input>
        <el-input placeholder="Observaciones" type="textarea" v-model="structure.observations">
        </el-input>
        <el-input placeholder="Porcentaje" v-model="structure.percentage">
          <template slot="append">%</template>
        </el-input>
      </el-card>

      <el-card>
        <div slot="header">
          <span>Instalaciones</span>
        </div>
        <el-input disabled>
          <template slot="prepend">$</template>
        </el-input>
        <el-input placeholder="Observaciones" type="textarea" v-model="installations.observations">
        </el-input>
        <el-input placeholder="Porcentaje" v-model="installations.hydraulic">
          <template slot="prepend">Hidraulica</template>
          <template slot="append">%</template>
        </el-input>
        <el-input placeholder="Porcentaje" v-model="installations.sanitary">
          <template slot="prepend">Sanitaria</template>
          <template slot="append">%</template>
        </el-input>
        <el-input placeholder="Porcentaje" v-model="installations.electrical">
          <template slot="prepend">Electrica</template>
          <template slot="append">%</template>
        </el-input>
        <el-input placeholder="Porcentaje" v-model="installations.gas">
          <template slot="prepend">Gas</template>
          <template slot="append">%</template>
        </el-input>
        <el-input placeholder="Porcentaje" disabled>
          <template slot="append">%</template>
        </el-input>
      </el-card>

      <el-card>
        <div slot="header">
          <span>Acabados</span>
        </div>
        <el-input disabled>
          <template slot="prepend">$</template>
        </el-input>
        <el-input placeholder="Observaciones" type="textarea" v-model="finishes.observations">
        </el-input>
        <el-input placeholder="Porcentaje" v-model="finishes.paint">
          <template slot="prepend">Pintura</template>
          <template slot="append">%</template>
        </el-input>
        <el-input placeholder="Porcentaje" v-model="finishes.tiling">
          <template slot="prepend">Lambrines</template>
          <template slot="append">%</template>
        </el-input>
        <el-input placeholder="Porcentaje" v-model="finishes.flatten">
          <template slot="prepend">Aplanado</template>
          <template slot="append">%</template>
        </el-input>
        <el-input placeholder="Porcentaje" v-model="finishes.ironworks">
          <template slot="prepend">
            <div class="long-label">
              Cancelería y herrería
            </div>
          </template>
          <template slot="append">%</template>
        </el-input>
        <el-input placeholder="Porcentaje" v-model="finishes.carpentry">
          <template slot="prepend">Carpintería</template>
          <template slot="append">%</template>
        </el-input>
        <el-input placeholder="Porcentaje" v-model="finishes.waterproof">
          <template slot="prepend">
            <div class="long-label">
              Impermeabilizante
            </div>
          </template>
          <template slot="append">%</template>
        </el-input>
        <el-input placeholder="Porcentaje" v-model="finishes.percentage">
          <template slot="append">%</template>
        </el-input>
      </el-card>

      <el-card>
        <div slot="header">
          <span>Cimentación</span>
        </div>
        <el-select placholder="Seleccionar" disabled>
        </el-select>
        <el-input placeholder="Observaciones" type="textarea" v-model="foundation.observations">
        </el-input>
        <el-input placeholder="Porcentaje" v-model="foundation.percentage">
          <template slot="append">%</template>
        </el-input>
      </el-card>

      <el-card>
        <div slot="header">
          <span>Ecotécnicas</span>
        </div>
        <el-input disabled>
          <template slot="prepend">$</template>
        </el-input>
        <el-input placeholder="Observaciones" type="textarea" v-model="ecotechnical.observations">
        </el-input>
        <el-input placeholder="Porcentaje" v-model="ecotechnical.percentage">
          <template slot="append">%</template>
        </el-input>
      </el-card>

      <el-card>
        <div slot="header">
          <span>Sustentabilidad</span>
        </div>
        <el-input disabled>
          <template slot="prepend">$</template>
        </el-input>
        <el-input placeholder="Observaciones" type="textarea" v-model="sustentability.observations">
        </el-input>
        <el-input placeholder="Porcentaje" v-model="sustentability.percentage">
          <template slot="append">%</template>
        </el-input>
      </el-card>

      <el-card>
        <div slot="header">
          <span>Porcentaje General</span>
        </div>
        <el-input placeholder="Porcentaje" disabled>
          <template slot="append">%</template>
        </el-input>
      </el-card>

      <el-card>
        <div slot="header">
          <span>¿Calidad de obra?</span>
        </div>
        <el-select placeholder="Seleccionar" v-model="workQuality">
          <el-option
              label="Buena"
              :value="0"
              />
            <el-option
                label="Regular"
                :value="1"
                />
              <el-option
                  label="Mala"
                  :value="2"
                  />
        </el-select>
      </el-card>

      <el-card>
        <div slot="header">
          <span>¿Hallazgos?</span>
        </div>
        <el-select placeholder="Seleccionar" v-model="findings">
          <el-option
              label="Si"
              :value="0"
              />
            <el-option
                label="No"
                :value="1"
                />
        </el-select>
      </el-card>

      <el-card>
        <div slot="header">
          <span>Observaciones Generales</span>
        </div>
        <el-input placeholder="Descibra" type="textarea" v-model="generalObservations">
        </el-input>
      </el-card>
    </div>

  </el-card>
</template>

<script>
export default {
  name: 'InterventionDataForm',
  data () {
    return {
      preliminaries: {
        observations: '',
        percentage: 0
      },
      demolitions: {
        observations: '',
        percentage: 0
      },
      structure: {
        observations: '',
        percentage: 0
      },
      installations: {
        observations: '',
        hydraulic: 0,
        sanitary: 0,
        electrical: 0,
        gas: 0
      },
      finishes: {
        observations: '',
        paint: 0,
        tiling: 0,
        flatten: 0,
        ironworks: 0,
        carpentry: 0,
        waterproof: 0,
        percentage: 0
      },
      foundation: {
        observations: '',
        percentage: 0
      },
      ecotechnical: {
        observations: '',
        percentage: 0
      },
      sustentability: {
        observations: '',
        percentage: 0
      },
      generalPercentage: 0,
      workQuality: 0,
      findings: 0,
      generalObservations: ''
    }
  }
}
</script>

<style scoped lang="scss">
.intervention-data-form {
  margin: 1rem 0;
}

.forms-section {
  > * {
    margin: 1rem 0;
  }
}

.long-label {
  width: 5rem;
  overflow-wrap: anywhere;
  white-space: normal;
}

.el-input {
  margin: 1rem 0;
}
</style>
